.App {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100vw;
	height: 100vh;
}

.section-title {
	width: 100%;
	text-align: center;
	margin: 0px;
}
