#skills {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	flex-shrink: 0;
	height: fit-content;
	width: 100vw;
}
.skills-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	align-content: center;
	flex-grow: 1;
	min-height: fit-content;
}
.skill-cards-container {
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	flex-wrap: wrap;
	flex-grow: 1;
	min-height: fit-content;
}

.skill-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	fill: var(--color1);
	color: var(--color1);
}
/* slower */
.inactive.skill-card {
	transition: width 0.25s ease-in-out 0s, height 0.25s ease-in-out 0.5s;
}
/* faster */
.active.skill-card {
	transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
}
.inactive .skill-description,
.inactive a {
	font-size: 0px;
	transition: font-size 0.25s ease 0s;
}
.active .skill-description,
.active a {
	font-size: 14px;
	transition: font-size 0.1s ease 0.5s;
}
.active,
.skill-card:hover {
	transition: width 0.5s ease-in-out 0s, height 0.5s ease-in-out 0s,
		font-size 3s ease 0.5s;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--background2);
}

.skill-description {
	text-align: center;
	font-size: 1px;
}
.skill-card a {
	color: var(--color1);
}

@media (max-width: 600px) {
	.skills-container {
		padding: 20px;
	}
	.skill-card {
		width: 40px;
		height: 70px;
		padding: 5px 10px;
		margin: 10px;
		box-shadow: 3px 5px 10px 2px rgba(0, 0, 0, 0.2);
	}

	.inactive .skill-name {
		font-size: 0px;
		transition: font-size 0.25s ease 0s;
	}
	.active .skill-name {
		font-size: 20px;
		font-weight: 400;
		transition: font-size 0.2s ease 0.5s;
	}

	.skill-logo {
		height: 30px;
		width: 30px;
		object-fit: cover;
		margin: 5px;
	}
	.active .skill-logo {
		height: 50px;
		width: 50px;
		margin: 10px;
	}
	.active {
		width: 150px;
		height: 200px;
		padding-bottom: 10px;
	}
	.active .skill-name {
		display: flex;
		margin: 5px;
	}
	.active .skill-description,
	.active a {
		font-size: 12px;
	}
	#skills {
		min-height: calc(var(--vh, 1vh) * 100 - var(--bar-hg-mb));
	}
}
@media (min-width: 600px) {
	#skills {
		min-height: calc(var(--vh, 1vh) * 100 - var(--bar-hg-dt));
	}
	.skill-card {
		width: 120px;
		height: 150px;
		box-shadow: 3px 5px 10px 2px rgba(0, 0, 0, 0.2);
		padding: 15px 0 25px 0;
		margin: 25px;
	}
	.active {
		width: 180px;
		height: 300px;
	}

	.active .skill-name {
		font-weight: 600;
		font-size: 20px;
	}

	.skill-description {
		margin: 10px 10px;
		font-size: 14px;
	}
	.skill-logo {
		height: 90px;
		width: 90px;
		object-fit: cover;
		margin: 10px;
	}
}
