.small-drawing {
	width: 150px;
	height: 150px;
	border: 4px solid var(--color1);
	overflow: hidden;
	border-radius: 50%;
	box-shadow: 0px 0px 0px 1px rgb(0, 0, 0) inset;
	margin-bottom: 5vh;
}

.drawing-container {
	position: relative;
	height: 100%;
	width: 100%;
	z-index: -1;
}
.drawing-img {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.small-drawing .drawing-container {
	background-color: var(--background4);
}

.full-drawing {
	width: 350px;
	height: 90%;
}
.full-drawing .drawing-img {
	object-fit: contain;
}
#border {
	opacity: 1;
	filter: var(--dr-border);
}
#body-fill {
	opacity: 1;
	filter: var(--dr-fill);
}

#linework {
	opacity: 1;
	filter: var(--dr-lines);
}
#shadows {
	opacity: 0.4;
	filter: var(--dr-shadows);
}

/* ABOUT ME DRAWING IN MOBILE VIEW */
@media (max-width: 600px) {
	.full-drawing {
		display: none;
	}
}

/* ABOUT ME DRAWING IN DESKTOP VIEW */
@media (min-width: 600px) {
	#about .small-drawing {
		display: none;
	}
}
