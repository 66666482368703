.main-page {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.main-content {
    width: 100%;
    display: flex;
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    overscroll-behavior: none;
    align-items: center;
    justify-content: stretch;
    flex-direction: column;
    color: var(--color1);
    background-color: var(--background1);
    box-shadow: inset 0px -15px 15px -3px rgba(0, 0, 0, 0.1);
    z-index: 5;
}

.section-title {
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-background {
    flex-shrink: 0;
    width: 100%;
}

.section-title {
    background-color: var(--background3);
}

.header-active- {
    display: none;
}
#root,
body {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 5;
}
@media (max-width: 600px) {
    header,
    .header-background,
    .footer,
    .nav-bar a,
    .empty-space.header_hidden- {
        height: 60px;
        flex-shrink: 0;
    }
    .section-title {
        height: 30px;
    }
    .page-section {
        scroll-margin-top: var(--bar-hg-mb);
    }
}
@media (min-width: 600px) {
    header,
    .header-background,
    .footer,
    .nav-bar a,
    .empty-space.header_hidden- {
        height: 100px;
        flex-shrink: 0;
    }
    .section-title {
        height: 80px;
    }
    .page-section {
        scroll-margin-top: var(--bar-hg-dt);
    }
}
