.welcome-screen {
    position: relative;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    background-color: var(--background1);
    color: var(--color1);
    align-items: center;
    flex-direction: column;

    z-index: 1;
}

.view-work {
    margin: 2rem;
    background-color: var(--background4);
    border: none;
    color: var(--color3);
    font-weight: 600;
    border-radius: 10px;
    padding: 5px 20px;
}
