.footer {
	width: 100vw;
	height: inherit;
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	align-items: center;
	background-color: var(--background2);
	color: var(--color3);
}

.links {
	text-decoration: none;
}

.link-logo {
	width: auto;
}

#linkedin-logo {
	filter: grayscale(1) brightness(5);
}

.link-group {
	margin-top: 10px;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

#footer-text {
	font-size: 12px;
	margin: 2px;
	font-weight: 100;
}

@media (max-width: 600px) {
	.link-logo {
		height: 25px;
		flex-shrink: 0;
	}
	.link-group {
		width: 100px;
	}
}
@media (min-width: 600px) {
	.link-logo {
		height: 30px;
	}
	.link-group {
		width: 200px;
	}
}
