.drawing {
    height: 10rem;
    aspect-ratio: 1;
    border: 2px solid white;
    border-radius: 50%;
    stroke: white;
    background-color: var(--background4);
}

.drawing-container-svg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

#linework-svg {
    fill: none;
    stroke: black;
    stroke-width: 1;
    animation: line 1.2s linear forwards 0.25s;
}
#outline-svg {
    fill: none;
    stroke: black;
    stroke-width: 3;
    animation: line 1.2s linear forwards 0.25s;
}
#outline2-svg {
    fill: none;
    stroke: white;
    stroke-width: 12;
    animation: line 1.2s linear forwards 0.25s;
}
#border-svg {
    fill: none;
    stroke: black;
    stroke-width: 3;
}
#body-svg {
    fill: white;
}
#background-svg {
    fill: var(--background4);
}
#linework-svg,
#outline-svg {
    stroke-dasharray: 1;
    stroke-dashoffset: 1;
}

@keyframes line {
    from {
        stroke-dashoffset: 1;
    }
    to {
        stroke-dashoffset: 0;
    }
}
@media (min-width: 600px) {
    .drawing {
        transform: scale(1.5);
    }
    .drawing-container-svg {
        height: 15rem;
    }
}
