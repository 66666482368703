#about {
	display: flex;
	flex-direction: column;
	width: 100vw;
	flex-shrink: 0;
	height: fit-content;
	justify-content: center;
	align-items: center;
}
.about-text {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

.about-bio {
	display: flex;
	flex-direction: column;
	text-align: justify;
	justify-content: space-around;
	align-items: center;
}
#bio-end {
	text-align: center;
}
#about-title {
	text-align: center;
	font-weight: 200;
	margin-top: 0.5em;
}
#about-name {
	margin: 0;
}

.about-container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	width: 90vw;
	height: 100%;
	margin-bottom: 20px;
}
.about-image {
	display: flex;
	align-items: center;
	justify-content: center;
}
.white-space {
	flex-grow: 1;
}
/* PHONE */
@media (max-width: 600px) {
	.about-bio {
		flex-grow: 1;
		width: 90%;
		max-height: 200px;
		font-size: 14px;
	}
	.about-bio-text {
		margin: 0.5em;
	}
	.about-container {
		flex-direction: column;
	}
	.about-image {
		flex-grow: 1;
		min-height: 50vw;
		width: 40vw;
		margin-top: 20px;
	}
	.about-text {
		flex-grow: 1;
	}
	#about .section-title {
		margin-bottom: 0px;
	}
	#about {
		justify-content: space-between;
		min-height: calc(var(--vh, 1vh) * 100 - var(--bar-hg-dt));
	}
	.about-image.full {
		display: none;
	}
}
/* DESKTOP */
@media (min-width: 600px) {
	.about-container {
		flex-direction: row;
	}
	.about-image {
		height: 100%;
		width: 40vw;
	}
	.about-text {
		max-width: 600px;
		margin-right: 10vw;
		font-size: 15px;
		font-weight: 300;
	}
	.about-image.small {
		display: none;
	}
	#about {
		min-height: calc(var(--vh, 1vh) * 100 - var(--bar-hg-mb));
	}
}
