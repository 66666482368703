@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;600&display=swap");
body {
	margin: 0;
	font-family: "Poppins";
	width: 100%;
	height: 100%;
	overscroll-behavior: none;
	position: fixed;
	top: 0;
	bottom: 0;
}

a,
button,
textarea,
input {
	font-family: "Poppins";
}
html {
	height: 100;
	height: calc(var(--vh, 1vh) * 100);
	min-height: -webkit-fill-available;
	overflow: hidden;
}
#root {
	display: flex;
	height: 100%;
	width: 100%;
}
@media (max-width: 600px) {
	.desktop-only {
		display: none;
	}
}
@media (min-width: 600px) {
	.mobile-only {
		display: none;
	}
}

:root {
	--bar-hg-mb: 60px;
	--bar-hg-dt: 100px;
}
